"use client";
import React from "react";

import { matter } from "app/fonts";

const LoginLayout = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className={`${matter.className}`}>
      <div className="align-center flex h-screen w-full flex-1 flex-col items-center justify-between bg-[#FBFBF6] px-4 py-12 sm:px-6 lg:px-8">
        {children}
      </div>
    </div>
  );
};

export default LoginLayout;
